/**
 * Created by bootdo.
 * 用户相关api
 */
import * as API from '../../utils/request';

export default {
    getNotify: params => {
        return API.GET('msg/getNotify', params)
    },
    addNotify: params => {
        return API.POSTARRAY('msg/addNotifMsg', params)
    },
    delNotify: params => {
        return API.POSTARRAY('msg/delNotifMsg', params)
    },
}