/**
 * Created by maqiang34.
 *  数据监控中心
 */
import * as API from '../../utils/request';

export default {

  //获取下拉框
   getObjType: params => { return API.GET('util/na_getObjType', params)},
   //获得Tree
   getUserTree: params => {return API.GET('dept/getDeptTree', params) },



}
