import * as API from '../../utils/request';

export default {

    add: params => { return API.POST('dept/add', params)},
    update: params => { return API.PUT('dept/update', params)},
    delete: params => { return API.DELETE('dept/delete', params)},

    getNodeList: params =>{return API.GET('dept/getNodeList',params) },
    //
    getRootNode: params => { return API.GET('dept/root', params)},
    //
    getUserTree: params => { return API.GET('dept/getDeptTree', params)},
}
