/**
 * Copyright (C) SmartCold 版权所有
 *
 * @author MaQiang34
 * @Description:共用JS
 * @createDate:2018-12-03 10:28
 * @version:V.0.1
 *
 */
export default {
    // Tree icon
    DEPTTYPES: [
         {value:0,  name: '系统', icon: 'el-icon-s-home', color: '#808080'},
         {value:1, name: '集团', icon: 'el-icon-school', color: '#FF4500'},
         {value:2, name: '站点', icon: 'iconfont bigicon iconweixiu1', color: '#FFD700'},
         {value:3, name: '用户', icon: 'el-icon-user', color: '#00FF00'},
         {value:4, name: '用户', icon: 'el-icon-user', color: '#00FF00'},
         {value:5, name: '用户', icon: 'el-icon-user', color: '#00FF00'},
         {value:6, name: '用户', icon: 'el-icon-user', color: '#00FF00'},
    ],

    getStartTime (befday) {
        const start = new Date()
        start.setTime(start.getTime() - 86400000 * befday)
        return start
    },
    dateFtt (fmt, date) {
        if (typeof date == "string") {
            date = new Date(date);
        }
        let o = {
            'M+': date.getMonth() + 1,
            'd+': date.getDate(),
            'h+': date.getHours(),
            'm+': date.getMinutes(),
            's+': date.getSeconds(),
            'q+': Math.floor((date.getMonth() + 3) / 3),
            S: date.getMilliseconds()
        }
        if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, String(date.getFullYear()).substr(4 - RegExp.$1.length))
        for (let k in o)
            if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(String(o[k]).length))
        return fmt
    },
    dowFile(file, filename) {
        const blobObject = new Blob([file])
        // 是IE浏览器
        if (!!window.ActiveXObject || 'ActiveXObject' in window) {
            window.navigator.msSaveOrOpenBlob(blobObject, fileName)
        } else {
            // 火狐谷歌都兼容.模板中要有一个预定义好的a标签
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blobObject)
            link.download = filename
            link.click()
            link.remove()
        }
    }
}
